import { v4 as getNewGuid } from 'uuid';
import { type Trigger, triggers } from '../fxOrders/fxOrdersModel';
import type { ValidationResponseDetailContent } from './api/validate';
import {
  type StopLossOrder,
  type StopLossOrderError,
  StopLossOrderFieldName,
  StopLossOrderStatus,
} from './fxOrderBulkModel';

export const loadStopLossOrderCSV = (content: string, notificationEmails: string[]): StopLossOrder[] => {
  const allRows = content.split('\n');

  return allRows
    .slice(1)
    .filter((row) => row.length > 0 && row !== '\r') // remove empty lines
    .map((row) => {
      const allColumns = row.split(';');

      const id = getNewGuid();

      const bdrId = allColumns[0].trim();
      const rawCurrencyPair = allColumns[1].trim();
      const way = allColumns[2].trim() === 'B' ? 'Bid' : 'Ask';
      const notional = Number(allColumns[3].trim());
      const currency = allColumns[4].trim();
      const limitPrice = Number(allColumns[5].trim());
      const expiryDate = allColumns[6].trim();
      const trigger = allColumns[7].trim();

      const currencyPair = `${rawCurrencyPair.slice(0, 3)}/${rawCurrencyPair.slice(3)}`;

      const allCurrencies = currencyPair.split('/');

      if (!checkTrigger(trigger)) {
        throw 'trigger is invalid';
      }

      const errors: StopLossOrderError[] = [];

      if (!allCurrencies.includes(currency)) {
        errors.push({
          field: StopLossOrderFieldName.currency,
          message: 'currency not comptible with the currencyPair',
          code: -1,
        });
      }
      return {
        id,
        omsId: undefined,
        currencyPair,
        bdrId,
        way,
        notional,
        currency,
        currencyIndex: allCurrencies[0] === currency ? 1 : 2,
        limitPrice,
        expiryDate: expiryDate.length > 0 ? new Date(expiryDate) : undefined,
        trigger,
        notificationEmails,
        status: StopLossOrderStatus.IDLE,
        errors: [],
      } satisfies StopLossOrder;
    });
};

const checkTrigger = (trigger: string): trigger is Trigger => {
  // @ts-ignore
  return triggers.includes(trigger);
};

/*
Instrument -> EQD reference ?
Qty -> 1 | 2 -> notional ?
Way -> B | S
PriceType -> always LIMIT ?
CodeType -> always FLOOR ?
Portfolio -> linked to the BdrId ?
Price -> stop ?
StopPrice -> limit price ?
Validity -> always Gtc ?
Restriction -> always Stop ?


Instrument;Qty;Way;PriceType;CodeType;Portfolio;Price;StopPrice;Validity;Restriction
FutGoldCME;1;B;Limit;FLOOR;POOL_DEFAULT;2760.9;2759.9;Gtc;Stop
FutBund;2;S;Limit;FLOOR;POOL_DEFAULT;133.03;133.04;Gtc;Stop
FutGoldCME;1;B;Limit;FLOOR;POOL_DEFAULT;2780.9;2779.9;Gtc;Stop
 */

export const sortByBrdAndCurrencyPair = (left: StopLossOrder, right: StopLossOrder) => {
  const leftKey = `${left.bdrId}${left.currencyPair}}`;
  const rightKey = `${right.bdrId}${right.currencyPair}}`;

  if (leftKey < rightKey) {
    return -1;
  }

  if (leftKey > rightKey) {
    return 1;
  }

  return 0;
};

export const sortByCurrencyPair = (left: StopLossOrder, right: StopLossOrder) => {
  if (left.currencyPair < right.currencyPair) {
    return -1;
  }

  if (left.currencyPair > right.currencyPair) {
    return 1;
  }

  return 0;
};

export const extractErrorsFromValidation = (
  validationResponseDetailContent: ValidationResponseDetailContent[],
): StopLossOrderError[] => {
  return validationResponseDetailContent.map(
    (details: { field: string; errorDescription: string; errorCode: number }) =>
      ({
        field: details.field as StopLossOrderFieldName, // TODO: remove this cast ?
        message: details.errorDescription,
        code: details.errorCode,
      }) satisfies StopLossOrderError,
  );
};
